<template>
  <section class="main-section">
    <div class="filters">
      <label>Filter by</label>
      <ul>
        <li v-if="me.is_admin">
          <user-groups-filter
            v-model="filters.user_groups"
            :label="filter_label('groups')"
          />
        </li>
        <li>
          <user-segment-filter
            v-model="filters.user_segments"
            :label="filter_label('segments')"
          />
        </li>
      </ul>
    </div>

    <div class="content">
      <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table
            v-if="activity.length > 0"
            :rows="activity"
            :columns="[
              'Date',
              'User',
              'Privacy',
              'Award earned',
              'Points earned',
            ]"
            :sortable="{
              '1': 'created_at',
              '2': 'for_users.0.name',
              '3': 'award.name',
              '4': 'points',
            }"
          >
            <template #rows="{rows, sort_by}">
              <tr
                v-for="(row, index) in rows"
                :key="`activity_row_${sort_by}_${index}`"
              >
                <td>
                  {{ $formatDate(row.created_at) }}
                </td>
                <td>
                  <user-card :user="row.for_users[0]" :height="22" />
                </td>
                <td>
                  {{
                    privacyLabel(row.for_users[0].metadata.anniversary_public)
                  }}
                </td>
                <td>{{ row.award.name }}</td>
                <td>
                  {{ $formatPoints(row.points) }}
                </td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>
            No activity found with these filters.
          </div>
        </template>
        <loading-indicator v-else />
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from 'lodash';

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups';
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments';

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    UserSegmentFilter,
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity();
      },
    },
  },
  data() {
    return {
      activity: null,
      filters: {
        user_groups: [],
        user_segments: [],
        date_range: this.dateRange,
      },
    };
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    this.populateActivity();

    this.$parent.$on('exportTable', this.export);
  },
  methods: {
    populateActivity: debounce(async function() {
      const resp = await this.$api.Reporting.get_anniversaries(this.filters);
      this.activity = resp;
    }, 250),
    filter_label(filter) {
      switch (filter) {
        case 'segments':
          return this.filters.user_segments.length > 0
            ? `Segments (${this.filters.user_segments.length})`
            : 'Segments';
        case 'groups':
          return this.filters.user_groups.length > 0
            ? `Channels (${this.filters.user_groups.length})`
            : 'Channels';
      }
    },
    privacyLabel(v) {
      switch (v) {
        case 'PRIVATE':
          return 'Private';
        case 'SKIP':
          return 'Not Celebrating';
        default:
          return 'Public';
      }
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce(
        (agg, [key, val]) => {
          if (key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
          return agg;
        },
        []
      );

      window.generateCSV('HiThrive Anniversary Report.csv', [
        [
          'Date',
          'Recipient',
          'Privacy',
          'Award earned',
          'Points earned',
          'Anniversary interval',
          ...custom_fields,
        ],
        ..._.map(this.activity, (r) => [
          this.$csvDateFormat(r.created_at),
          r.for_users[0].email,
          r.award.name,
          this.privacyLabel(r.for_users[0].metadata.anniversary_public),
          r.points,
          r.award.anniversary_interval === -1
            ? 'Yearly'
            : `${r.award.anniversary_interval} year`,
          ...Object.entries(r).reduce((agg, [k, v]) => {
            if (k.substr(0, 3) === 'CF_') agg.push(v);
            return agg;
          }, []),
        ]),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 25px 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  ::v-deep > table {
    font-size: 14px;
    margin: 0;

    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>
